import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css'
import DatePicker from 'react-datepicker'
import { Link, useLocation } from 'react-router-dom';
import { format } from "date-fns";
import Select from "react-select";
import * as textMessage from "../../../Config/Constant";
import redCross from "../../../assets/images/CommonComponent/redCross.svg";
import calendarIcon from '../../../assets/images/CommonComponent/calendarIcon.png';
import greenPlus from '../../../assets/images/CommonComponent/greenPlus.svg';
import historyIcon from '../../../assets/images/CommonComponent/history.svg';
import AppContainer from '../../../components/AppContainer/AppContainer';
import { useNavigate } from 'react-router-dom';
import { updateActivity, viewActivityDetails } from '../../../redux/actions/MasterDataAction/ActivityAction/ActivityAction';
import { getUserRoleList } from '../../../redux/actions/UserManagementAction/TeamMembersAction/TeamMembersAction';
import { useSelector, useDispatch } from 'react-redux';
import { createNotification } from '../../../Config/NotificationToast';
import * as moment from 'moment';
import {
    durationOptions, frequencyOptions, weekdays
} from '../../../utils/Helper';
import './EditActivity.scss';
import { FormControl, FormControlLabel, Radio, RadioGroup, Checkbox, CircularProgress } from '@mui/material';
import { EDIT_ACTIVITY } from '../../../Router/RoutesURL';
import Modal from 'react-bootstrap/Modal';
import CrossIcon from '../../../assets/images/CommonComponent/CrossIcon.svg'

const AddActivity = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const { activityId } = location.state;
    const [title, setTitle] = useState('');
    const [selectedDuration, setSelectedDuration] = useState('');
    const [venue, setVenue] = useState('');
    const [capacity, setCapacity] = useState();
    const [date, setDate] = useState('');
    const [selectedTime, setSelectedTime] = useState("00:00:00");
    const [notes, setNotes] = useState('');
    const [showBtns, setShowBtns] = useState(false);
    const [repeatActivity, setRepeatActivity] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [selectedDays, setSelectedDays] = useState(weekdays);
    const {loading} = useSelector(state => state?.viewActivityDetails);
    const viewActivityDataRes = useSelector(state => state?.viewActivityDetails?.viewActivityDetails?.data);
    const updateActivityReducer = useSelector(state => state?.updateActivity);
    const [updateKey, setUpdateKey] = useState(0);

    const [showModal, setShowModal] = useState(false);

    const customStyles = {
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: 400
        }),
    };

    useEffect(() => {
        const sendRequest = {
            "pageNo": 1,
            "pageSize": "",
        }
        dispatch(getUserRoleList(sendRequest));
    }, []);

    useEffect(() => {
        const sendRequest = {
            "activity_id": activityId
        };
        dispatch(viewActivityDetails(sendRequest));
        setShowModal(false)
        setUpdateKey(0)
    }, [location.state]);

    useEffect(() => {
        if ((title !== viewActivityDataRes?.title
            || venue !== viewActivityDataRes?.venue
            || capacity !== viewActivityDataRes?.no_of_capacity
            || selectedTime !== moment(viewActivityDataRes?.activity_datetime).format('HH:mm:ss')
            || date !== moment(viewActivityDataRes?.activity_datetime).format('DD/MM/YYYY')
            || notes !== viewActivityDataRes?.notes
            || JSON.stringify(selectedDuration) !== JSON.stringify({ value: viewActivityDataRes?.duration, label: `${viewActivityDataRes?.duration} Minutes` })
            || repeatActivity !== !!parseInt(viewActivityDataRes?.is_repeated)
            || (selectedFrequency && selectedFrequency?.value !== viewActivityDataRes?.frequency?.key))
            && ((repeatActivity && selectedFrequency) || !repeatActivity)
        ) {
            setShowBtns(true);
        } else {
            setShowBtns(false);
        }
    }, [title, venue, capacity, selectedTime, date, notes, selectedDuration, repeatActivity, selectedFrequency, selectedDays])

    useEffect(() => {
        if (viewActivityDataRes) {
            setTitle(viewActivityDataRes?.title);
            setVenue(viewActivityDataRes?.venue);
            setCapacity(viewActivityDataRes?.no_of_capacity);
            setDate(moment(viewActivityDataRes?.activity_datetime).format('DD/MM/YYYY'));
            setSelectedTime(moment(viewActivityDataRes?.activity_datetime).format('HH:mm:ss'))
            setNotes(viewActivityDataRes?.notes);
            setSelectedDuration({ value: viewActivityDataRes?.duration, label: `${viewActivityDataRes.duration} Minutes` });
            setRepeatActivity(viewActivityDataRes?.is_repeated && !!parseInt(viewActivityDataRes?.is_repeated))
            let frequency = viewActivityDataRes?.frequency && JSON.parse(viewActivityDataRes?.frequency)
            setSelectedFrequency(frequencyOptions?.filter((item)=> item.value === frequency?.key)?.[0])
            setSelectedDays(frequency?.key === 'weekly' && frequency?.value)
        }
    }, [viewActivityDataRes])

    const cancelSubmit = () => {
        navigate(-1)
    };

    const submitData = () => {
        const validEmail = textMessage.PHONE_REGEX;
        if (!title || !notes || !date || !selectedTime || !venue || !capacity || !selectedDuration || ((repeatActivity && !selectedFrequency))) {
            createNotification('warning', 'All * fields are required');
        }else if (!validEmail.test(capacity)) {
            createNotification('warning', 'Capacity must be Number*');
            return false;
        }else if (!viewActivityDataRes.repeat_ref_id && viewActivityDataRes.is_repeated==='0'){
            handleUpdate(0)
        }
        else{
            setShowModal(true)
        }
    }

    const handleUpdate = (type)=>{

        const dateObje = moment(date, 'DD/MM/YYYY').format('yyyy-MM-DD');
        const dateTimeString = `${dateObje}T${selectedTime}`;
        const localDate = new Date(dateTimeString);
        const utcDate = localDate.toISOString().split('T')[0]; // UTC date in format "YYYY-MM-DD"
        const utcTime = localDate.toISOString().split('T')[1].slice(0, 8); // UTC time in format "HH:mm:ss"

        let frequency = selectedFrequency?.value === 'daily' 
            ? {"key" : "daily",  "value" : ""}
            : selectedFrequency?.value === 'weekly'
                ? {"key" : "weekly",  "value" : [...selectedDays]}
                : selectedFrequency?.value === 'monthly'
                    ? {"key" : "monthly",  "value" : utcDate + " " + utcTime}
                    :null

        const sendRequest = {
            "id": activityId,
            "title": title,
            "notes": notes,
            "activity_datetime": utcDate + " " + utcTime,
            "venue": venue,
            "no_of_capacity": Number(capacity),
            "duration": selectedDuration?.value,
            "is_repeated": + repeatActivity,
            "frequency": frequency ? JSON.stringify(frequency) : "",
            "update_all" : type,
            "repeat_ref_id": viewActivityDataRes.repeat_ref_id || activityId,
            onSuccessCallback: ()=>{
                navigate(-1);
            }
        };
        dispatch(updateActivity(sendRequest));
        setShowModal(false)
        setUpdateKey(0)
    }

    const handleChange = (val) => {
        setSelectedDuration(val)
    }

    const handleFrequencyChange = (val)=>{
        setSelectedDays(weekdays)   
        setSelectedFrequency(val)
    }

    const handleSelectDays = (e)=>{
        if(e.target.checked){
            setSelectedDays((prev)=>{
                return [...prev.filter(item => item.day !== e.target.value), {value: 1, day: e.target.value}]
            })
        }else{
            setSelectedDays((prev)=>{
                return [...prev.filter(item => item.day !== e.target.value), {value: 0, day: e.target.value}]
            })
        }
    }

    const handleChangeCapacity = (val) => {
        const regexName = textMessage.PHONE_REGEX;
        const nameFormat = regexName.test(val);
        if (val === "") {
            setCapacity(val);
        } else if (nameFormat && val.length < 12) {
            setCapacity(val);
        }
    }

    const handleChangeStartDate = (val) => {
        // const formattedDate = format(val, "yyyy-MM-dd");
        setDate(format(val, 'dd/MM/yyyy'));
    }

    const SetTime = (date) => {
        setSelectedTime(moment(date).format("HH:mm:ss"));
    }

    const handleRepeatActivity = (e)=>{
        if(date){
            setRepeatActivity((prev)=> !prev)
            if(!e.target.checked){
                setSelectedDays(weekdays)
                setSelectedFrequency(null)
            }
        }
    }

    const visitMasterActivity = ()=>{
        navigate(EDIT_ACTIVITY, {state: {activityId: viewActivityDataRes?.repeat_ref_id}})
    }

    return (
        <AppContainer history={history}>
            <div className="event-content">
                <div className='edit-activity'>
                    {   
                        loading && 
                        <div className='loader'>
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>
                    }
                    {
                        !loading && viewActivityDataRes
                        &&
                        <div className="card">
                            <div className="card-header">
                                <h4 className='mb-0'>Edit Activity  </h4>
                                <div className=''>
                                    <button type="button" className="btn btn-danger redButton mr-3" onClick={cancelSubmit}>
                                        <img className="mainIcon mr-1" src={redCross} alt="" width="22px" height="22px" />
                                        CANCEL</button>

                                    <button type="submit" className="btn btn-success greenButton" onClick={submitData}>
                                            {
                                                updateActivityReducer.loading ?
                                                <>
                                                    <CircularProgress size={30} sx={{'color': '#83C52B'}}/>
                                                </>
                                                :
                                                <>
                                                    <img className="mainIcon mr-2" src={greenPlus} alt="" width="16px" height="16px" /> UPDATE
                                                </>
                                            }
                                        </button>
                                </div>
                            </div>
                            <div className="separator"></div>
                            <div className="card-body ">
                                <div className='row cardDiv'>
                                    <div className='col'>
                                        <div className='d-flex'>
                                            <div className="mb-3 pr-0">
                                                <label htmlFor="exampleFormControlInput1" className="form-label Label">Title*</label>
                                                <input
                                                    className="form-control Title"
                                                    type="text"
                                                    value={title}
                                                    placeholder="Enter title"
                                                    onChange={(e) => { setTitle(e.target.value) }}
                                                />
                                            </div>
                                            <div className="mb-3 d-flex ml-4">
                                                <div className="mb-3 pr-0">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label Label">Venue*</label>
                                                    <input
                                                        className="form-control Title"
                                                        type="text"
                                                        placeholder="Enter venue"
                                                        value={venue}
                                                        onChange={(e) => { setVenue(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='d-flex'>
                                            <div className="mb-3 pr-0">
                                                <label htmlFor="exampleFormControlInput1" className="form-label Label">Capacity*</label>
                                                <div className="mb-3 pr-0 d-flex phoneNoInput">
                                                    <input
                                                        className="form-control Title"
                                                        type="text"
                                                        placeholder="Enter capacity"
                                                        value={capacity}
                                                        onChange={(event) => handleChangeCapacity(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 d-flex ml-4">
                                                <div className="mb-3 pr-0 d-flex align-items-end justify-content-between activity-date-div">
                                                    <div>
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Date*</label>
                                                        <div className='dateBlock'>
                                                            {date == "" ?
                                                                <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Select date</p></div>
                                                                : <div className="dayDateTextDiv" ><p className="dayDateText">{date}</p></div>
                                                            }
                                                            <div className='datePickerDiv'>
                                                                <DatePicker
                                                                    minDate={new Date()}
                                                                    onChange={date => { handleChangeStartDate(date) }}
                                                                    customInput={
                                                                        <img src={calendarIcon} className="calIcon" alt="" />
                                                                    }
                                                                    showYearDropdown={true}
                                                                    showMonthDropdown={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        viewActivityDataRes?.repeat_ref_id ? 
                                                        <> <div className='visit-master-link mb-3' onClick={visitMasterActivity} > <span>View Master Activity</span> </div> </>
                                                        :
                                                        <div className='d-flex align-items-center mb-1'>
                                                            <div>
                                                                <Checkbox
                                                                checked={repeatActivity}
                                                                onChange={handleRepeatActivity}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                size="small"
                                                                    sx={{
                                                                        color: "#74613C",
                                                                        '&.Mui-checked': {
                                                                        color: "#74613C",
                                                                        },
                                                                    }}
                                                                    />
                                                            </div>
                                                            <div>Repeat activity</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            repeatActivity
                                            &&
                                            <div className='d-flex'>
                                                <div className="mb-3 pr-0">
                                                    <label htmlFor="frequency" className="form-label Label">Frequency*</label>
                                                    <div className='frequencyBlock'>
                                                        <Select
                                                            placeholder="Select"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            onChange={handleFrequencyChange}
                                                            value={selectedFrequency}
                                                            options={frequencyOptions}
                                                            styles={customStyles}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                isFocused: "#74613C",
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#F5F5F5',
                                                                    primary: '#74613C',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 d-flex ml-4 align-items-end">
                                                    <div className="mb-3 pr-0">
                                                        {
                                                            selectedFrequency?.value === "weekly" && 
                                                                <div className='weekly-frequency'>
                                                                    <div className="check-box-input d-flex">
                                                                        {
                                                                            weekdays?.length && weekdays.map((item, index)=>(
                                                                                <div className="check-box" key={index}>
                                                                                    <input type="checkbox" name='day' value={item.day} 
                                                                                        checked={selectedDays.filter(e => e.day === item.day)[0].value}
                                                                                        onChange={(e)=>{handleSelectDays(e, item)}}
                                                                                    />
                                                                                    <label htmlFor={item.day}> {item.day}</label>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='d-flex'>
                                            <div className="mb-3 pr-0">
                                                <label htmlFor="exampleFormControlInput1" className="form-label Label">Time*</label>
                                                <div className="mb-3 pr-0 d-flex phoneNoInput">
                                                    <div className="dateSubBlock">
                                                        <div className="dayDateTextDiv" >
                                                            <p className="dayDateText">{selectedTime}</p>
                                                        </div>
                                                        <DatePicker
                                                            className='timeCal'
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            onChange={date => { SetTime(date) }}
                                                            timeFormat={"HH:mm"}
                                                            timeIntervals={15}
                                                            customInput={<img src={historyIcon} className="remIcon" alt="" width="18" height="18" />}
                                                            dateFormat="Pp"
                                                            timeCaption="Hour"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 d-flex ml-4">
                                                <div className="mb-3 pr-0">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label Label">Duration*</label>
                                                    <div className='durationBlock'>
                                                        <Select
                                                            placeholder="Duration"
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            onChange={handleChange}
                                                            value={selectedDuration}
                                                            options={durationOptions}
                                                            styles={customStyles}

                                                            theme={(theme) => ({
                                                                ...theme,
                                                                isFocused: "#74613C",
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#F5F5F5',
                                                                    primary: '#74613C',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className="mb-3 pr-0">
                                                <label htmlFor="exampleFormControlInput1" className="form-label Label">Notes*</label>
                                                <textarea className="form-control textArea" id="exampleFormControlTextarea1" rows="3" placeholder="Enter notes"
                                                    value={notes}
                                                    onChange={(e) => { setNotes(e.target.value) }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {showModal && <UpdateModal showModal={showModal} setShowModal={setShowModal} updateKey={updateKey} handleUpdate={handleUpdate} setUpdateKey={setUpdateKey} />}
                </div>
            </div >
        </AppContainer >
    )
}
export default AddActivity


export const UpdateModal = ({showModal, setShowModal, handleUpdate, updateKey, setUpdateKey}) =>{
    return (
        <Modal centered className="TeamMember-modal-warning br-8" show={showModal} onHide={() => { setUpdateKey(0); setShowModal(false) }}>
                <Modal.Header className='w-100'>
                    <div className='d-flex position-relative justify-content-center w-100'>
                        <div className="modalText pb-4">Update Activity</div>
                        <div className="cross--icon position-absolute" style={{right: '15px'}} onClick={() => { setUpdateKey(0); setShowModal(false) }}><img src={CrossIcon}/></div>
                    </div>
                </Modal.Header>
                <Modal.Body className='pt-2 float-start'>
                    <div style={{marginLeft: '-60px'}}>
                        <FormControl>
                            <RadioGroup
                                sx={{ display: 'flow-root' }}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group" onChange={(e)=>{setUpdateKey(parseInt(e.target.value))}}>
                                <FormControlLabel checked={updateKey === 0} value={0} name="vegNonVeg"  className="mr-4" control={<Radio size='small' />} label="Save This Only" />
                                <br/>
                                <FormControlLabel checked={updateKey === 1} value={1} name="vegNonVeg" control={<Radio size='small' />} label="Save All" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Modal.Body>
                <Modal.Footer className='mt-2'>
                    <button type="button" className="btn btn-danger redButton mr-3" onClick={()=> {setUpdateKey(0); setShowModal(false)}}>
                        Cancel</button>
                    <button type="button" className="btn btn-success greenButton " onClick={()=>{handleUpdate(updateKey)}}>
                        Ok</button>
                </Modal.Footer>
            </Modal>
    )
}