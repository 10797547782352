import {
    GET_TESTIMONIAL_LIST,
    GET_TESTIMONIAL_LIST_SUCCESS,
    GET_TESTIMONIAL_LIST_FAILURE,
    UPDATE_TESTIMONIAL_STATUS,
    UPDATE_TESTIMONIAL_STATUS_SUCCESS,
    UPDATE_TESTIMONIAL_STATUS_FAILURE,

} from '../../../constants';

export function getTestimonialListAction(data) {
    return {
        type: GET_TESTIMONIAL_LIST,
        data,
    };
}

export function getTestimonialListSuccessAction(data) {
    return {
        type: GET_TESTIMONIAL_LIST_SUCCESS,
        data,
    };
}

export function getTestimonialListFailureAction(data) {
    return {
        type: GET_TESTIMONIAL_LIST_FAILURE,
        data,
    };
}


export function updateTestimonialStatusAction(data) {
    return {
        type: UPDATE_TESTIMONIAL_STATUS,
        data,
    };
}

export function updateTestimonialStatusSuccessAction(data) {
    return {
        type: UPDATE_TESTIMONIAL_STATUS_SUCCESS,
        data,
    };
}

export function updateTestimonialStatusFailureAction(data) {
    return {
        type: UPDATE_TESTIMONIAL_STATUS_FAILURE,
        data,
    };
}