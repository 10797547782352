import { call, put, takeEvery } from 'redux-saga/effects'
import { getProgramFeedbacks_API, getTestimonials_API, updateTestimonialStatus_API, viewProgramFeedbacks_API} from '../../../../Config/Api';
import { createNotification } from '../../../../Config/NotificationToast';
import { logOut } from "../../../../utils/Helper";
import { instance } from "../../../auth/axiosInstance";

export default function* programFeedback() {
    yield takeEvery('GET_PROGRAM_FEEDBACK_LIST', getProgramFeedbackListSaga);
    yield takeEvery('VIEW_PROGRAM_FEEDBACK_DETAILS', viewProgramFeedbackDetailsSaga);
}

const getProgramFeedbackList = async (URL) => {
    try {
        const response = await instance.get(URL);
        return response;
    } catch (errors) {
        console.log("errors", errors);
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getProgramFeedbackListSaga(action) {

    const URL = `${getProgramFeedbacks_API}?page_no=${action.data.pageNo}&page_size=${action.data.pageSize}${(action.data.filter_text) ? `&filter_text=${action.data.filter_text}` : ""}${(action.data.filter_from) ? `&filter_from=${action.data.filter_from}` : ""}${(action.data.filter_to) ? `&filter_to=${action.data.filter_to}` : ""}`

    yield put({ type: 'LOADING_FAILURE', loading: true });

    try {
        const response = yield call(getProgramFeedbackList, URL);
        const getProgramFeedbackListDataRes = response
        if (getProgramFeedbackListDataRes.statusCode === 200) {
            yield put({ type: 'GET_PROGRAM_FEEDBACK_LIST_SUCCESS', getProgramFeedbackList: getProgramFeedbackListDataRes });
            yield put({ type: 'GET_PROGRAM_FEEDBACK_LIST_FAILURE', message: getProgramFeedbackListDataRes.message });
            yield put({ type: 'LOADING_FAILURE', loading: false });
        } else {
            if (getProgramFeedbackListDataRes.statusCode === 401) {
                logOut('error', getProgramFeedbackListDataRes.message)
            } else {
                createNotification('error', getProgramFeedbackListDataRes.message);
                yield put({ type: 'GET_PROGRAM_FEEDBACK_LIST_FAILURE', message: getProgramFeedbackListDataRes.message });
                yield put({ type: 'LOADING_FAILURE', loading: false });
            }
        }
    } catch (e) {
        yield put({ type: 'GET_PROGRAM_FEEDBACK_LIST_FAILURE', message: "Ananda program feedback data does not exists" });
    }
}


const viewProgramFeedbackDetails = async (URL) => {
    try {
        const response = await instance.get(URL);
        return response;
    } catch (errors) {
        console.log("errors", errors);
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* viewProgramFeedbackDetailsSaga(action) {


    yield put({ type: 'LOADING_FAILURE', loading: true });


    try {
        const URL = `${viewProgramFeedbacks_API}?&user_program_id=${action?.data?.user_program_id }&user_id=${action.data?.user_id}`
        const response = yield call(viewProgramFeedbackDetails, URL);

        const viewProgramFeedbackDetailsDataRes = response

        if (viewProgramFeedbackDetailsDataRes.statusCode === 200) {
            yield put({ type: 'VIEW_PROGRAM_FEEDBACK_DETAILS_SUCCESS', viewProgramFeedback: viewProgramFeedbackDetailsDataRes });
            yield put({ type: 'VIEW_PROGRAM_FEEDBACK_DETAILS_FAILURE', message: viewProgramFeedbackDetailsDataRes.message });
            yield put({ type: 'LOADING_FAILURE', loading: false });
        } else {
            if (viewProgramFeedbackDetailsDataRes.statusCode === 401) {
                logOut('error', viewProgramFeedbackDetailsDataRes.message)
            } else {
                createNotification('error', viewProgramFeedbackDetailsDataRes.message);
                yield put({ type: 'VIEW_PROGRAM_FEEDBACK_DETAILS_FAILURE', message: viewProgramFeedbackDetailsDataRes.message });
                yield put({ type: 'LOADING_FAILURE', loading: false });
            }
        }
    } catch (e) {
        yield put({ type: 'VIEW_PROGRAM_FEEDBACK_DETAILS_FAILURE', message: "Ananda program feedback data does not exists" });
    }
}