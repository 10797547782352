import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#74613C",

        },
        otherColor: {
            main: "#CED4DA",
        }
    },
});