import * as type from '../../constants';

const initialState = {
    getTestimonialList: [],
    updateTestimonialStatus: [],
    loading: false,
    error: null,
}

export function getTestimonialList(state = initialState, action) {
    switch (action.type) {
        case type.GET_TESTIMONIAL_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TESTIMONIAL_LIST_SUCCESS:
            return {
                ...state,
                getTestimonialList: action.getTestimonialList,
                loading: false,
                error: null
            }
        case type.GET_TESTIMONIAL_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}

export function updateTestimonialStatus(state = initialState, action) {
    switch (action.type) {
        case type.UPDATE_TESTIMONIAL_STATUS:
            return {
                ...state,
                loading: true,
            }
        case type.UPDATE_TESTIMONIAL_STATUS_SUCCESS:
            return {
                ...state,
                updateTestimonialStatus: action.updateTestimonialStatus,
                loading: false,
                error: null
            }
        case type.UPDATE_TESTIMONIAL_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}




