import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Divider from '@mui/material/Divider';
import styled from '@emotion/styled';
import Select from "react-select";
import {
    FormGroup,
} from "reactstrap";
import {
    stableSort,
    rowOptions,
} from '../../../utils/Helper'
import * as routes from "../../../Router/RoutesURL";
import { useSelector, useDispatch } from 'react-redux';
import {getGuestDinacharyasList} from '../../../redux/actions/ReportAction/GuestDinacharyaAction/GuestDinacharyaAction'
import './GuestDinacharya.scss';

import { CircularProgress } from '@mui/material';
import moment from 'moment';

const MainHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    if (orderBy === "duration") orderBy = "durationOne"
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: 'guest_name',
        disablePadding: false,
        label: 'GUEST NAME',
    },
    {
        id: 'check_in_check_out',
        disablePadding: false,
        label: 'CHECK IN / CHECK OUT',
    },
    {
        id: 'no_of_activities',
        disablePadding: false,
        label: 'NO. OF ACTIVITIES',
    },
    {
      id: 'weekly_progress',
      disablePadding: false,
      label: 'WEEKLY PROGRESS',
    },
    {
      id: 'monthly_progress',
      disablePadding: false,
      label: 'MONTHLY PROGRESS',
    },
    {
      id: 'overall_progress',
      disablePadding: false,
      label: 'OVERALL PROGRESS',
    },
    {
        id: 'action',
        disablePadding: false,
        label: 'ACTION',
        disableSorting: true

    },
];

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) =>
                (<TableCell
                    key={headCell.id}
                    align='left'
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        hideSortIcon={headCell.id === 'action' ? true : false}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>)
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { pageNos, rowsPerPageNos, setShowLoader, showLoader, activeTab } = props;
    const dispatch = useDispatch();
    const localData = JSON.parse(localStorage.getItem("filtersReport"))
    const [searchText, setSearchText] = useState(localData?.guestDinacharya?.filter_text ||'');
   
    useEffect(() => {
        if (activeTab == "GuestDinacharya") {
            const localData = JSON.parse(localStorage.getItem("filtersReport"))
            if(!localData?.guestDinacharya?.filter_text){
                setSearchText('')
            }
        }
    }, [activeTab]);

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem("filtersReport"))
        let filtersValues = { "filter_text": searchText};
        localStorage.setItem("filtersReport", JSON.stringify({...localData, guestDinacharya: filtersValues}))
        setShowLoader(true)
    }, [searchText])

    const handleChangeSearch = (e) => {
        setSearchText(e.target.value)
        const sendRequest = {
            "pageNo": pageNos,
            "pageSize": rowsPerPageNos,
            "filter_text": e.target.value
        };
        dispatch(getGuestDinacharyasList(sendRequest));
    }

    return (
        <>
            <Toolbar sx={{
                pl: { sm: 2 },
                pt: { sm: 2 },
                pb: { sm: 2 },
                pr: { xs: 1, sm: 1 },

            }}
            >
                <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-3 col-xxl-3'>
                    <MainHeading
                        sx={{ flex: '1 1 100%', }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Guest Dinacharaya List
                    </MainHeading>
                </div>
                <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-9 col-xxl-9 LevelDiv'>

                    <div className="mr-sm-2 searchDiv class-search ">
                        <input
                            className="form-control mr-sm-2 class-search"
                            type="search"
                            placeholder=" Search Keyword"
                            aria-label="Search"
                            value={searchText}
                            onChange={handleChangeSearch}
                        ></input>
                    </div>
                </div>
            </Toolbar>
            <Divider />
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const GuestDinacharyaTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [getGuestDinacharyaListMainData, setgetGuestDinacharyaListMainData] = useState('');
    const [showNext, setShowNext] = useState(false);
    const [showPrev, setShowPrev] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate()
    const getGuestDinacharyaList = useSelector(state => state?.getGuestDinacharyaList);
    const getGuestDinacharyaListDataRes = useSelector(state => state?.getGuestDinacharyaList?.getGuestDinacharyaList);
    const btnPrev = ["btnPrev", showPrev ? "" : "opacityBtns"];
    const btnNext = ["btnNext", showNext ? "" : "opacityBtns"];
    const { activeTab } = props;
    const dispatch = useDispatch();

    useEffect(()=>{
        if(!getGuestDinacharyaList.loading)
            setShowLoader(false)
        if(!getGuestDinacharyaList.loading && !getGuestDinacharyaListDataRes?.data?.data && !getGuestDinacharyaList.error){
            setShowLoader(true)
        }
    }, [getGuestDinacharyaList.loading])

    useEffect(() => {
        if (activeTab == "GuestDinacharya") {
            const localData = JSON.parse(localStorage.getItem("filtersReport"))
            const sendRequest = {
                "pageNo": 1,
                "pageSize": rowsPerPage,
                "filter_text": localData?.guestDinacharya?.filter_text || '' ,
           };
           dispatch(getGuestDinacharyasList(sendRequest));
          }
    }, [activeTab]);
    
    useEffect(() => {
        if (getGuestDinacharyaList.error !== "Ananda guest dinacharya data does not exists") {
            setgetGuestDinacharyaListMainData(getGuestDinacharyaListDataRes && getGuestDinacharyaListDataRes?.data?.data);
            if (getGuestDinacharyaListDataRes?.data?.current_page_no === 1) {
                setShowPrev(false)
            } else {
                setShowPrev(true)
            }

            if (getGuestDinacharyaListDataRes?.data?.current_page_no === getGuestDinacharyaListDataRes?.data?.total_pages) {
                setShowNext(false)
            } else {
                setShowNext(true)
            }
        } else {
            setgetGuestDinacharyaListMainData('');
        }
    }, [getGuestDinacharyaListDataRes, getGuestDinacharyaList])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = getGuestDinacharyaListDataRes && getGuestDinacharyaListMainData.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePageNext = (newPage) => {
        setPage(newPage);
        setShowLoader(true)
        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));
        const sendRequest = {
            "pageNo": getGuestDinacharyaListDataRes?.data?.current_page_no + 1,
            "pageSize": rowsPerPage,
            "filter_text": filterdata?.guestDinacharya?.filter_text
        };
        dispatch(getGuestDinacharyasList(sendRequest));
    };
    
    const handleChangePagePrev = (newPage) => {
        setPage(newPage);
        setShowLoader(true)
        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));
        const sendRequest = {
            "pageNo": getGuestDinacharyaListDataRes?.data?.current_page_no - 1,
            "pageSize": rowsPerPage,
            "filter_text": filterdata?.guestDinacharya?.filter_text
        };
        dispatch(getGuestDinacharyasList(sendRequest));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.value);
        setPage(page);

        const filterdata = JSON.parse(localStorage.getItem("filtersReport"));

        const sendRequest = {
            "pageNo": 1,
            "pageSize": event.value,
            "filter_text": filterdata?.guestDinacharya?.filter_text
        };
        dispatch(getGuestDinacharyasList(sendRequest));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getGuestDinacharyaListDataRes && getGuestDinacharyaListMainData?.length) : 0;

    const handleViewGuestDinacharya = (userId, guestName) => {
        navigate(routes.VIEW_GUEST_DINACHARYA, { state: { userId, guestName } })
    };

    return (
        <div className="ProBox">
            <Box sx={{ width: '100%' }} className="guestDinacharyaBox">
                <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} pageNos={page}
                        showLoader={showLoader}
                        setShowLoader={setShowLoader}
                        rowsPerPageNos={rowsPerPage} 
                        activeTab ={activeTab} />
                {
                    showLoader ?
                    <div className='w-100 text-center py-5'>
                        <CircularProgress sx={{'color': '#74613C'}}/>
                    </div>
                    :
                    <>
                        
                        {getGuestDinacharyaListMainData && <TableContainer sx={{ pt: 1, pr: 3, pb: 3, pl: 3 }}>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"

                                size='small'
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={getGuestDinacharyaListDataRes && getGuestDinacharyaListMainData?.length}
                                />
                                <TableBody>
                                    {
                                        stableSort(getGuestDinacharyaListMainData, getComparator(order, orderBy))
                                            .slice((rowsPerPage * (page - 1)), (rowsPerPage * (page) + rowsPerPage))
                                            .map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow hover onClick={(event) => handleClick(event, row.title)} role="checkbox"

                                                            tabIndex={-1}
                                                            
                                                            key={index}

                                                            >
                                                            <TableCell align="left"  width={'40%'}>{row.guest_name}</TableCell>
                                                            <TableCell align="left"  width={'40%'}> 
                                                            {row.check_in && moment(row.check_in).format('DD/MM/YYYY')} - {row.check_out && moment(row.check_out).format('DD/MM/YYYY')}
                                                            </TableCell>
                                                            <TableCell align="left">{row.no_of_activities}</TableCell>

                                                            <TableCell align="left">{row.weekly_progress} %</TableCell>
                                                            <TableCell align="left">{row.monthly_progress} %</TableCell>
                                                            <TableCell align="left">{row.overall_progress} %</TableCell>
                                                            <TableCell align="left"
                                                                className=' ViewEditClick '
                                                                onClick={() => { handleViewGuestDinacharya(row.user_id, row.guest_name) }}><i className="fas fa-eye mr-2"></i>View</TableCell>                                                        
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })}
                                    {emptyRows > 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </>
                }
                </Paper>
                {
                    getGuestDinacharyaListMainData
                    &&
                    <div className='paginationBlock'>
                        <div className='textData'><p>Items per page: </p></div>
                        <FormGroup className="FormGroup has-float-label rowsSelect class-menu-dropdown ">
                            <Select
                                placeholder={rowsPerPage}
                                className="react-select"
                                classNamePrefix="react-select"
                                value={rowsPerPage}
                                options={rowOptions}
                                isSearchable={false}
                                onChange={handleChangeRowsPerPage}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F5F5F5',
                                        primary: '#74613C',
                                    },
                                })}
                                menuPlacement="top"
                                menuPosition="fixed"
                                styles={{
                                    menu: (base) => ({
                                        ...base,
                                        top: 'auto',
                                        bottom: 0,
                                    }),
                                }}
                            />
                        </FormGroup>
                        {getGuestDinacharyaListDataRes?.data && <p className='endText'>   {getGuestDinacharyaListDataRes?.data?.start} - {getGuestDinacharyaListDataRes?.data?.end} of {getGuestDinacharyaListDataRes?.data?.total}</p>}
                        <p>
                            <button className={btnPrev.join(' ')} onClick={() => { handleChangePagePrev(page) }}>
                                <ArrowBackIosNewSharpIcon className='iconBtn' />
                            </button>
                            <button className={btnNext.join(' ')} onClick={() => { handleChangePageNext(page) }}>
                                <ArrowForwardIosSharpIcon className='iconBtn' />
                            </button></p>
                    </div>   
                }
            </Box>
        </div>
    );
}
export default GuestDinacharyaTable;