import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { getGuestDinacharyas_API, viewGuestDinacharya_API} from '../../../../Config/Api';
import { createNotification } from '../../../../Config/NotificationToast';
import { logOut } from "../../../../utils/Helper";
import { instance } from "../../../auth/axiosInstance";

export default function* guestDinacharya() {
    yield takeLatest('GET_GUEST_DINACHARYA_LIST', getGuestDinacharyaListSaga);
    yield takeLatest('VIEW_GUEST_DINACHARYA_DETAILS', viewGuestDinacharyaDetailsSaga);
}

const getGuestDinacharyaList = async (URL) => {
    try {
        const response = await instance.get(URL);
        return response;
    } catch (errors) {
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getGuestDinacharyaListSaga(action) {

    const URL = `${getGuestDinacharyas_API}?page_no=${action.data.pageNo}&page_size=${action.data.pageSize}${(action.data.filter_text) ? `&filter_text=${action.data.filter_text}` : ""}${(action.data.filter_from) ? `&filter_from=${action.data.filter_from}` : ""}${(action.data.filter_to) ? `&filter_to=${action.data.filter_to}` : ""}`

    yield put({ type: 'LOADING_FAILURE', loading: true });

    try {
        const response = yield call(getGuestDinacharyaList, URL);
        const getGuestDinacharyaListDataRes = response
        if (getGuestDinacharyaListDataRes.statusCode === 200) {
            yield put({ type: 'GET_GUEST_DINACHARYA_LIST_SUCCESS', getGuestDinacharyaList: getGuestDinacharyaListDataRes });
            yield put({ type: 'GET_GUEST_DINACHARYA_LIST_FAILURE', message: getGuestDinacharyaListDataRes.message });
            yield put({ type: 'LOADING_FAILURE', loading: false });
        } else {
            if (getGuestDinacharyaListDataRes.statusCode === 401) {
                logOut('error', getGuestDinacharyaListDataRes.message)
            } else {
                createNotification('error', getGuestDinacharyaListDataRes.message);
                yield put({ type: 'GET_GUEST_DINACHARYA_LIST_FAILURE', message: getGuestDinacharyaListDataRes.message });
                yield put({ type: 'LOADING_FAILURE', loading: false });
            }
        }
    } catch (e) {
        yield put({ type: 'GET_GUEST_DINACHARYA_LIST_FAILURE', message: "Ananda guest dinacharya data does not exists" });
    }
}

const viewGuestDinacharyaDetails = async (URL) => {
    try {
        const response = await instance.get(URL);
        return response;
    } catch (errors) {
        console.log(errors)
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* viewGuestDinacharyaDetailsSaga(action) {


    yield put({ type: 'LOADING_FAILURE', loading: true });


    try {
        const URL = `${viewGuestDinacharya_API}?user_id=${action.data?.userId}&start_date=${action.data?.start_date}&end_date=${action.data?.end_date}`
        const response = yield call(viewGuestDinacharyaDetails, URL);

        const viewGuestDinacharyaDetailsDataRes = response
        if (viewGuestDinacharyaDetailsDataRes.statusCode === 200) {
            yield put({ type: 'VIEW_GUEST_DINACHARYA_DETAILS_SUCCESS', viewGuestDinacharya: viewGuestDinacharyaDetailsDataRes });
            yield put({ type: 'VIEW_GUEST_DINACHARYA_DETAILS_FAILURE', message: viewGuestDinacharyaDetailsDataRes.message });
            yield put({ type: 'LOADING_FAILURE', loading: false });
        } else {
            if (viewGuestDinacharyaDetailsDataRes.statusCode === 401) {
                logOut('error', viewGuestDinacharyaDetailsDataRes.message)
            } else {
                createNotification('error', viewGuestDinacharyaDetailsDataRes.message);
                yield put({ type: 'VIEW_GUEST_DINACHARYA_DETAILS_FAILURE', message: viewGuestDinacharyaDetailsDataRes.message });
                yield put({ type: 'LOADING_FAILURE', loading: false });
            }
        }
    } catch (e) {
        yield put({ type: 'VIEW_GUEST_DINACHARYA_DETAILS_FAILURE', message: "Ananda program feedback data does not exists" });
    }
}