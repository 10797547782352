import * as type from '../../constants';

const initialState = {
    getGuestDinacharyaList: [],
    viewGuestDinacharya: [],
    loading: false,
    error: null,
}

export function getGuestDinacharyaList(state = initialState, action) {
    switch (action.type) {
        case type.GET_GUEST_DINACHARYA_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_GUEST_DINACHARYA_LIST_SUCCESS:
            return {
                ...state,
                getGuestDinacharyaList: action.getGuestDinacharyaList,
                loading: false,
                error: null
            }
        case type.GET_GUEST_DINACHARYA_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}

export function viewGuestDinacharya(state = initialState, action) {
    switch (action.type) {
        case type.VIEW_GUEST_DINACHARYA_DETAILS:
            return {
                ...state,
                loading: true,
            }
        case type.VIEW_GUEST_DINACHARYA_DETAILS_SUCCESS:
            return {
                ...state,
                viewGuestDinacharya: action.viewGuestDinacharya,
                loading: false,
                error: null
            }
        case type.VIEW_GUEST_DINACHARYA_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}




