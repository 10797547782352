import {
    GET_PROGRAM_FEEDBACK_LIST,
    GET_PROGRAM_FEEDBACK_LIST_SUCCESS,
    GET_PROGRAM_FEEDBACK_LIST_FAILURE,
    VIEW_PROGRAM_FEEDBACK_DETAILS,
    VIEW_PROGRAM_FEEDBACK_DETAILS_SUCCESS,
    VIEW_PROGRAM_FEEDBACK_DETAILS_FAILURE,

} from '../../../constants';

export function getProgramFeedbackListAction(data) {
    return {
        type: GET_PROGRAM_FEEDBACK_LIST,
        data,
    };
}

export function getProgramFeedbackListSuccessAction(data) {
    return {
        type: GET_PROGRAM_FEEDBACK_LIST_SUCCESS,
        data,
    };
}

export function getProgramFeedbackListFailureAction(data) {
    return {
        type: GET_PROGRAM_FEEDBACK_LIST_FAILURE,
        data,
    };
}


export function viewProgramFeedbackDetailsAction(data) {
    return {
        type: VIEW_PROGRAM_FEEDBACK_DETAILS,
        data,
    };
}

export function viewProgramFeedbackDetailsSuccessAction(data) {
    return {
        type: VIEW_PROGRAM_FEEDBACK_DETAILS_SUCCESS,
        data,
    };
}

export function viewProgramFeedbackDetailsFailureAction(data) {
    return {
        type: VIEW_PROGRAM_FEEDBACK_DETAILS_FAILURE,
        data,
    };
}