import React, { useEffect, useState } from 'react'
import * as routes from "../../Router/RoutesURL";
import AppContainer from '../../components/AppContainer/AppContainer'
import { Tab, Tabs } from 'react-bootstrap';
import GuestDinacharyaTable from './GuestDinacharya/GuestDinacharya';
import ProgramFeedbacksTable from './ProgramFeedbacks/ProgramFeedbacks';
import TestinomialsTable from './Testimonials/Testimonials';
import './ReportsDashboard.scss'
import { getPermissionByAppName } from '../../utils/Helper';

const ReportsDashboard = (props) => {
    const { history } = props;
    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
        const activeTabData = localStorage.getItem('activeTabReports');
        if (activeTabData && activeTabData !=="GuestDinacharya") {
            setActiveTab(activeTabData);
        } else {
            setActiveTab("ProgramFeedbacks");
        }
    }, [])

    
    const handleTabSelect = (index) => {
        localStorage.setItem('activeTabReports', index);
        localStorage.removeItem('filtersReport');
        setActiveTab(index);
    }
    return (
        <AppContainer history={history}>
            <div className="event-table">
                <div className='ReportsContainer'>
                    
                    <Tabs id="controlled-tab-example"
                        className="TabsDiv"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                    >
                        
                        {/* <Tab eventKey="GuestDinacharya" title="Guest Dinacharya">
                            <GuestDinacharyaTable activeTab={activeTab} />
                        </Tab> */}

                        <Tab eventKey="ProgramFeedbacks" title="Program Feedbacks">
                            <ProgramFeedbacksTable activeTab={activeTab} />
                        </Tab>
                        
                        <Tab eventKey="Testimonials" title="Testimonials">
                            <TestinomialsTable activeTab={activeTab} permission={getPermissionByAppName("Reports")}/>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </AppContainer >
    )
}

export default ReportsDashboard
