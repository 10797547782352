import { call, put, takeEvery } from 'redux-saga/effects'
import { getTestimonials_API, updateTestimonialStatus_API} from '../../../../Config/Api';
import { createNotification } from '../../../../Config/NotificationToast';
import { logOut } from "../../../../utils/Helper";
import { instance } from "../../../auth/axiosInstance";

export default function* viewTestimonials() {
    yield takeEvery('GET_TESTIMONIAL_LIST', getAllTestimonialListSaga);
    yield takeEvery('UPDATE_TESTIMONIAL_STATUS', updateTestimonialStatusSaga);
}

const getAllTestimonialList = async (URL) => {
    try {
        const response = await instance.get(URL);
        return response;
    } catch (errors) {
        console.log("errors", errors);

        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* getAllTestimonialListSaga(action) {

    const URL = `${getTestimonials_API}?page_no=${action.data.pageNo}&page_size=${action.data.pageSize}${(action.data.filter_text) ? `&filter_text=${action.data.filter_text}` : ""}${(action.data.filter_from) ? `&filter_from=${action.data.filter_from}` : ""}${(action.data.filter_to) ? `&filter_to=${action.data.filter_to}` : ""}`

    yield put({ type: 'LOADING_FAILURE', loading: true });

    try {
        const response = yield call(getAllTestimonialList, URL);
        const viewTestimonialsDataRes = response
        if (viewTestimonialsDataRes.statusCode === 200) {
            yield put({ type: 'GET_TESTIMONIAL_LIST_SUCCESS', getTestimonialList: viewTestimonialsDataRes });
            yield put({ type: 'GET_TESTIMONIAL_LIST_FAILURE', message: viewTestimonialsDataRes.message });
            yield put({ type: 'LOADING_FAILURE', loading: false });
        } else {
            if (viewTestimonialsDataRes.statusCode === 401) {
                logOut('error', viewTestimonialsDataRes.message)
            } else {
                createNotification('error', viewTestimonialsDataRes.message);
                yield put({ type: 'GET_TESTIMONIAL_LIST_FAILURE', message: viewTestimonialsDataRes.message });
                yield put({ type: 'LOADING_FAILURE', loading: false });
            }
        }
    } catch (e) {
        yield put({ type: 'GET_TESTIMONIAL_LIST_FAILURE', message: "Ananda testimonials data does not exists" });
    }
}


const updateTestimonialsStatus = async (data) => {
    try {
        const response = await instance.post(updateTestimonialStatus_API, {
            data
        });
        return response;
    } catch (errors) {
        console.log("errors", errors);

        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}

function* updateTestimonialStatusSaga(action) {


    yield put({ type: 'LOADING_FAILURE', loading: true });
    const data={
        mark_feature : action.data.mark_feature,
        id : action.data.id,
        status : action.data.status,
    }

    try {
        const response = yield call(updateTestimonialsStatus, data);

        const updateTestimonialsStatusDataRes = response
        if (updateTestimonialsStatusDataRes.statusCode === 200) {
            yield put({ type: 'UPDATE_TESTIMONIAL_STATUS_SUCCESS', getTestimonialList: updateTestimonialsStatusDataRes });
            yield put({ type: 'UPDATE_TESTIMONIAL_STATUS_FAILURE', message: updateTestimonialsStatusDataRes.message });
            yield put({ type: 'LOADING_FAILURE', loading: false });
        } else {
            if (updateTestimonialsStatusDataRes.statusCode === 401) {
                logOut('error', updateTestimonialsStatusDataRes.message)
            } else {
                createNotification('error', updateTestimonialsStatusDataRes.message);
                yield put({ type: 'UPDATE_TESTIMONIAL_STATUS_FAILURE', message: updateTestimonialsStatusDataRes.message });
                yield put({ type: 'LOADING_FAILURE', loading: false });
            }
        }
    } catch (e) {
        yield put({ type: 'UPDATE_TESTIMONIAL_STATUS_FAILURE', message: "Ananda testimonials data does not exists" });
    }
}